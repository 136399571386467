import { useContext, useEffect } from "react";
import { graphql, navigate } from "gatsby";
import { Helmet } from "react-helmet";
import { edgesToArray, isBrowser } from "../../context/helpers";

import StoreContext from "../../context/store";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { useCurrentLang } from "../../hooks/useLocalizedCartInfo";

import { ComponentType } from "../../constants/ComponentType";
import ALCustomLandingPage from "../../components/al_components/ALCustomLandingPage";
import SEO from "../../components/seo";

function LandingPage({ path, data, pageContext }) {
  const { setDisplayQuickAccess } = useContext(StoreContext);
  const lang = useCurrentLang();

  if (pageContext.id === "null") {
    if (isBrowser) {
      navigate("/");
    }
    return null;
  }

  let landingPageContent = null;
  if (data?.allContentfulLandingPage) {
    const allBlocksArray = edgesToArray(data.allContentfulLandingPage);
    const defaultContent = allBlocksArray.find((n) => n.node_locale === "en-US");
    const blocksRoot = allBlocksArray.find((b) => b.node_locale === lang);
    landingPageContent = blocksRoot ?? defaultContent;
  }

  if (!pageContext) {
    return null;
  }
  useEffect(() => {
    setDisplayQuickAccess(false);
  }, []);

  return landingPageContent ? (
    <div>
      <SEO
        title={landingPageContent.title}
        description={landingPageContent?.seoContent?.description?.description || ""}
      />
      <Helmet>
        <link rel="canonical" href={`https://www.analuisa.com${path}`} />
        <meta property="og:url" content={`https://www.analuisa.com${path}`} />
      </Helmet>
      <ALCustomLandingPage data={landingPageContent} componentType={ComponentType.HP} />
    </div>
  ) : null;
}

export default withALErrorBoundary({
  name: "LandingPage",
  priority: "P2",
})(LandingPage);

export const query = graphql`
  query ($id: String!) {
    allContentfulLandingPage(filter: { handle: { eq: $id } }, limit: 1) {
      edges {
        node {
          node_locale
          title
          blocks {
            ...ContentfulLandingPageBlocks
          }
          seoContent {
            description {
              description
            }
          }
        }
      }
    }
  }
`;
